// Charge la base commune
import './common.js'

// Mainslider
async function getMainslider () {
  import(
    /* webpackChunkName: "mainslider", webpackPrefetch: true */ './js/components/mainslider.js'
  ).then(() => {
    /* global Waypoint */
    Waypoint.refreshAll()
  })
}

function getAlert () {
  import(/* webpackPrefetch: true */ './scss/components/alerts.scss')
}

// Real Estate Programs Search Form
async function getREPSForm ($f) {
  $f.load()
  import(
    /* webpackChunkName: "realEstateProgramsSearchForm", webpackPrefetch: true */ './js/components/realEstateProgramsSearchForm.js'
  ).then(() => {
    $f.load(false)
  })
}

// actions principales communes à toutes les pages
$(document).ready(function () {
  // gestion du slider principal
  $('.mainbanner').waypoint(
    function () {
      getMainslider()
      this.destroy()
    },
    {
      offset: '100%'
    }
  )

  $('.alert').waypoint(
    function () {
      getAlert()
      this.destroy()
    },
    {
      offset: '100%'
    }
  )

  // gestion du formulaire de recherche rapide des programmes
  const $e = $('.rep-search-form.in')
  if ($e.length) {
    $e.waypoint(
      function () {
        getREPSForm($e)
        this.destroy()
      },
      {
        offset: '100%'
      }
    )
  }
})
