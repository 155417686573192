/* fichier JS de base commun quelques soient les versions du projet web */
// charge bootstrap => nécessite jquery et popper => il est préférable de décomposer bootstrap en sélectionnant les modules communs utilisés sur toutes les pages du site
// import 'bootstrap'
// exemples en ne chargeant que les modules ci-dessous (généralement utilisés pour le header du site)
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/util'

// lazy loading => https://github.com/aFarkas/lazysizes
import lazySizes from 'lazysizes'
/* global Waypoint */
import 'waypoints/lib/jquery.waypoints.min.js'

// bootstrap => customisez bootstrap directement depuis un fichier custom et sélectionnez les modules communs utilisés sur toutes les pages du site au lieu de tout importer
// import 'bootstrap/scss/bootstrap'
import './scss/bootstrap'
// global styles
import './scss/custom'

// Scripts librairies
import { tClick } from './js/libs/utils'
lazySizes.cfg.expand = 80
lazySizes.cfg.expFactor = 1
$('body').removeClass('no-js')

// lazysizes : add simple support for background images :
document.addEventListener('lazybeforeunveil', function (e) {
  const bg = e.target.getAttribute('data-bg')
  if (bg) e.target.style.backgroundImage = 'url(' + bg + ')'
})

// Scroll to top
async function getScrollToTop () {
  import(/* webpackChunkName: "scrollToTop" */ './js/libs/scrollToTop/scrollToTop.js').then(() => {
    $.scrollUp({
      easingType: 'linear',
      topSpeed: 300,
      scrollSpeed: 400,
      animation: 'fade',
      scrollText: '<i class="icon-top"></i>'
    })
  })
}

// OwlCarousel
async function getOwlCarousel () {
  import(/* webpackChunkName: "owlCarousel" */ './js/components/owlCarousel.js').then(() => {
    Waypoint.refreshAll()
  })
}

// Real Estate Locations Autocomplete
async function getRELAutocomplete ($f) {
  import(/* webpackChunkName: "realEstateLocationsAutocomplete" */ './js/components/realEstateLocationsAutocomplete.js').then(module => {
    const form = module.relAutocomplete
    form.init($f)
  })
}

// Collapsing content
async function getCollaps () {
  import(/* webpackChunkName: "collaps" */ './js/components/collaps.js')
}

// sticky header
function sticky (_w) {
  const _h = $('#header')
  const s = 'sticky'
  if ((_w.scrollTop() > 32 && _w.width() > 991) || (_w.scrollTop() > 0 && _w.width() <= 991)) { _h.addClass(s) } else { _h.removeClass(s) }
}

// Actions principales communes à toutes les pages
$(document).ready(function () {
  const _w = $(window)

  // sur le scroll
  _w.scroll(function () {
    if (_w.scrollTop() >= 100 && !$('#scrollUp').length) { getScrollToTop() }
    sticky(_w)
  })

  const _h = $('#header')
  //  var _qhmf = '.qhmForm.out'
  _h.find('.dropdown-toggle').on('mouseenter click', function () {
    $(this).parent().find('.dropdown-menu').addClass('d-block')
    //    if ($(this).parent().find(_qhmf).length && !$(_qhmf).hasClass('loaded')) {
    //      getQhmFormOut($(_qhmf))
    //    }
  })

  // navbar toggler
  $('[data-trigger]').on('click', function () {
    $($(this).attr('data-trigger')).toggleClass('show')
    $('.navbar-overlay').toggleClass('visible')
  })
  $('.navbar-close, .navbar-overlay').click(function () {
    $('.navbar-collapse').removeClass('show')
    $('.navbar-overlay').removeClass('visible')
  })

  // ancre
  $('.scroll').each(function () {
    const $this = $(this)
    $this.on('click', function (e) {
      const t = ($(e.target).is('a')) ? this.hash : $(this).attr('target')
      e.preventDefault()
      if ($this.length > 0) {
        const h = $('#header').height()
        $('html, body').animate({ scrollTop: $(t).offset().top - h - 20 }, 500, function () {
          $('html,body').animate({ scrollTop: $(t).offset().top - h - 20 }, 300)
        })
      }
    })
  })

  // gestion des carousel
  $('.owl-carousel.c').waypoint(function () {
    getOwlCarousel()
    this.destroy()
  }, {
    offset: '100%'
  })

  // filtres des offres autocomplete
  $('form[name="rep-search-form"] input[name="search"]').on('click', function () {
    $(this).blur().off('click').attr('disabled', '')
    getRELAutocomplete($(this).parents('form'))
  })

  // gestion du formulaire de recherche rapide des programmes
  const $c = $('.collaps')
  if ($c.length) {
    $c.waypoint(function () {
      getCollaps($(this))
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // refresh waypoints au chargement d'une image
  $('.lazyload').on('lazyloaded', () => {
    Waypoint.refreshAll()
  })

  sticky(_w)
  tClick()
})
